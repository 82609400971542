import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtxxmwAoM56G3B1Ht61N2h6NXMrCzLw9w",
  authDomain: "probook-aedfe.firebaseapp.com",
  projectId: "probook-aedfe",
  storageBucket: "probook-aedfe.appspot.com",
  messagingSenderId: "145415385223",
  appId: "1:145415385223:web:5ad3e55d7f3de952fc0822",
  measurementId: "G-186QNML0TF",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export default { app, firestore, auth, storage };
