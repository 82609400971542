import PropTypes from "prop-types";
import React, { useState, useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { Redirect } from "react-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firestore from "./config/firebase";
import firebase from "./config/firebase";
// import * as firebase from './config/firebase';
// const db = firestore;

// home pages

const HomeElectronicsTwo = lazy(() =>
  import("./pages/home/HomeElectronicsTwo")
);
const ChatComponent = lazy(() => import("./pages/other/chat"));
const ProviderServicesComponent = lazy(() =>
  import("./pages/other/provider/providerServices")
);
const ProviderAddNewService = lazy(() =>
  import("./pages/other/provider/provideraddNewService")
);
const ProviderAppointments = lazy(() =>
  import("./pages/other/provider/providerAppointments")
);
const ProviderSchedule = lazy(() =>
  import("./pages/other/provider/providerSchedule")
);
const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));

// shop pages

const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}
function PrivateRoute({ component: Component, authenticated, ...rest }) {
  // const isUser = firebase.auth.currentUser != null;
  console.log(rest);
  firebase.auth.onAuthStateChanged((n) => {
    console.log(n != null);
    const isLoggedIn = n != null;
    if (isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: rest.path,
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login-register",
          }}
        />
      );
    }
  });
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login-register",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
const App = (props) => {
  let [isAuthenticated, setisAuthenticated] = useState(true);
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });
  useEffect(() => {
    firebase.auth.onAuthStateChanged((n) => {
      console.log(n != null);
      setisAuthenticated(n != null);
    });
  }, []);

  // const [user, setUser] = useState(true);
  // useEffect(() => {
  //   const auth = getAuth();
  //   const unsubscribe = auth.onAuthStateChanged((userAuth) => {
  //     const user = {
  //       uid: userAuth?.uid,
  //       email: userAuth?.email,
  //     };
  //     if (userAuth) {
  //       console.log(userAuth);
  //       setUser(user);
  //     } else {
  //       setUser(null);
  //     }
  //   });
  //   return unsubscribe;
  // }, []);

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {/* <div className="App">
                      {user ? <HomeElectronicsTwo /> : <LoginRegister />}
                    </div> */}

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeElectronicsTwo}
                />

                {/* Homepages */}
                {/* <PublicRoute component={HomeElectronicsTwo}  > </PublicRoute> */}
                <Route
                  path={process.env.PUBLIC_URL + "/home-electronics-two"}
                  component={HomeElectronicsTwo}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/home-christmas"}
                  component={HomeChristmas}
                />

                {/* Shop pages */}

                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                  component={ShopGridFullWidth}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-standard"}
                  component={ShopListStandard}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                  component={ShopGridTwoColumn}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                  component={ProductFixedImage}
                />

                {/* Blog pages */}

                <Route
                  path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                  component={BlogNoSidebar}
                />
                {/* Chat pages */}
                <PrivateRoute
                  component={MyAccount}
                  authenticated={isAuthenticated}
                  path="/my-account"
                ></PrivateRoute>
                <PrivateRoute
                  component={ChatComponent}
                  authenticated={isAuthenticated}
                  path="/chat"
                ></PrivateRoute>
                <PrivateRoute
                  component={ProviderAddNewService}
                  authenticated={isAuthenticated}
                  path="/provider/add-new"
                ></PrivateRoute>
                <PrivateRoute
                  component={ProviderAppointments}
                  authenticated={isAuthenticated}
                  path="/provider/appointments"
                ></PrivateRoute>
                <PrivateRoute
                  component={ProviderSchedule}
                  authenticated={isAuthenticated}
                  path="/provider/schedule"
                ></PrivateRoute>
                <PrivateRoute
                  component={ProviderServicesComponent}
                  authenticated={isAuthenticated}
                  path="/provider/services"
                ></PrivateRoute>

                {/* Other pages */}

                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                {/* <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                /> */}
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />
                {/* {isAuthenticated ? (
                 
                ) : (
                  <Redirect
                    to={{
                      pathname: "/my-account",
                      state: { from: props.location },
                    }}
                  />
                )} */}
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route exact component={NotFound} />

                {/* <Suspense
                  fallback={  */}
                {/* <div className="App">
                      {user ? <HomeElectronicsTwo /> : <LoginRegister />}
                    </div> */}
                {/* }
                ></Suspense>  */}
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
